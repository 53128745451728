import React from 'react';

export default function ModalExplanation() {
	return (

		<div className="modal-content">
			<h1>Modal Interchange Chart</h1>

			<p>Welcome to the Modal Interchange Chart! This interactive music theory tool was conceived to help users
			better understand modes and how to borrow their qualities to spice up chord progressions. We hope the
			following guide helps you utilize this tool whether you're learning your scales on guitar, improvising on
			piano, or songwriting with the aim of creating tasty chord progressions.</p>

			<h2>What Is Modal Interchange?</h2>

			<p>Now, my goal here is not to confuse you about modes. I'll leave that to countless other music theory sites already out there. Modes are a huge topic in music theory with many ways of understanding them, which brings many ways to end up confused and frustrated.</p>

			<p>However, an understanding of music theory, modes and functional harmony isn't required here (though it might certainly help). I will try and provide practical examples below that will help you achieve results regardless of your level of understanding in music theory. After all, the best way to learn is by doing. And we are here to make music, aren't we?</p>

			<p>Oh, but you asked a question!</p>

			<p>In short, Modal interchange is when a chord from a parallel mode is
			adopted into a progression that is largely derived from another mode or scale.</p>

			<h2>A Couple of Things First</h2>

			<p>Before we start using the chart I want to talk about two things. First, my examples will primarily revolve around the <strong>major scale</strong> (<em>Ionian</em>) and the <strong>natural minor scale</strong> (<em>Aeolian</em>), because those are by far the most commonly used modes in western music.Their popularity transcends the others by so much that they were given more common names, while the rest remained named after Greek islands. There are more modes in existence besides those displayed in the modal interchange chart, but for the sake of understanding, let’s resist going down that rabbit hole for now.</p>

			<p>Second, you will notice that chords exist in several of the modes. This might lead to some confusion at first as to where our borrowed chord(s) is really coming from. However, our melody will almost always strongly indicate the mode in which our chord was borrowed from by bringing out its character. After all, what is music without a good melody.</p>

			<h2>Using the Chart - The Basics</h2>

			<h3>Example 1</h3>

			<p>Let's take a chord progression in C Ionain, with the Triads button selected. How about the classic I - IV - V - I? One of the most common, plain Jane, original corn flakes progressions out there. Pick up your guitar or sit down at your piano and give it a whirl. I encourage you to sing a melody along with it - anything that comes out. Looking at our chart in C, that is:</p>

			<p>C - F - G - C</p>

			<p>If you are anything like me, this chord progression won’t be anything to write home about. This is where we can utilize modal interchange to add some spice to our chord progression.</p>

			<p>Now, let's pick a chord in that progression - how about the IV chord - and look at other IV chords on the chart. Dorian and Mixolydian both have a major IV, which is what we already have. Phrygian, Aeolian and Locrian all have a minor iv chord. Lydian has a diminished sharp &#9839;iv&ordm; chord. All of these could be candidates for using modal interchange in our progression. Let's go with a minor <strong>iv</strong> chord. Now play the progression again, this time as:</p>

			<p>C - Fm - G - C</p>

			<p>Sounds a bit different, doesn’t it? Maybe just a bit spicier than before?</p>

			<p>Congratulations, you just applied the concept of modal interchange!</p>

			<p>Remember, in its current state, that <em>iv</em> chord technically could have been borrowed from Phrygian, Aeolian (natural minor) or Locrian. There really isn't any way to be sure until a melody is painted over top, highlighting the unique scale degree(s) of the mode from which we borrowed. To think of it another way, you can imagine that borrowed <em>iv</em> chord as having an ambiguous home. Singing a melody over top can give clues as to which home it comes from.  More on this later.</p>

			<h3>Example 2</h3>

			<p>Let's get a little more adventurous now. For this example we will use a longer progression in the key of E Aeolian, and use 7th chords (because we can). Let’s take:</p>

			<p>i<sup>7</sup>- &#9837;VI<sup>7</sup> - v<sup>7</sup> - &#9837;VII<sup>7</sup></p>

			<p>Looking at Aeolian in E, we can see that that would be:</p>
			
			<p>Em<sup>7</sup> - Cmaj<sup>7</sup> - Bm<sup>7</sup> - D<sup>7</sup></p>

			<p>Play around with it and get familiar with how it sounds.</p>

			<p>Now, if we were to be more intentional with our choices we might do something like target the Lydian mode for our I chord which would now give us Emaj<sup>7</sup>(I<sup>7</sup>). Taking this example a step further, let's also swap out our v<sup>7</sup> chord for the V<sup>7</sup> of Ionian - a B<sup>7</sup>. Now we have an Aeolian progression that borrows a chord from Lydian, and a chord from Ionain, and looks like this:</p>

			<p>Emaj<sup>7</sup> - Cmaj<sup>7</sup> - B<sup>7</sup> - D<sup>7</sup></p>

			<p>We've done it again!</p>

			<p>In the next example we will touch on how we can make clear to the listener that what they are hearing are in fact the modes we intended to borrow from. But first..</p>

			<h2>The Chart Design and Creating Scales</h2>

			<p>You may have noticed that the chart's boxes do not align for the scale degrees of each mode. This illustrates the intervallic relationship of the notes in each mode and to help the user identify their scale pattern. To use the chart in this way, we need to ignore the chord qualities of each box and only focus on the note letter. For example, C Ionian has the following sequence of whole steps (W) and half steps (H):</p>

			<p>Ionian: W W H W W W H</p>

			<p>W W H W W W H</p>

			<img alt="mode" src={require(`../assets/explanationImages/ionian.png`)} className="modal-content__image" />

			<p>
			The larger boxes coincide with whole steps, while the smaller boxes indicate half steps. For instance, C is in a large box, therefore it takes a whole step to get from C to D, whereas E is in a small box and only needs a half step to reach F. To create an Ionain scale, just pick a starting note (in this case C), and move up the sequence (ignoring the chord qualities) until it loops back on itself an octave higher:</p>

			<p>C - D - E - F - G - A - B - C</p>

			<p>Great! Now by looking at the chart, we can extract the scale patterns for each of the modes. This will come in handy when we create melodies over our modal interchange ridden progressions.</p>

			<h3>Example 2.5</h3>

			<p>Now let's jump back into our progression from example 2:</p>

			<p>Emaj<sup>7</sup> - Cmaj<sup>7</sup> - B<sup>7</sup> - D<sup>7</sup></p>

			<p>We are in the key of E Aeolian, and using 7ths, so let's adjust the chart again to reflect this and have a look at Lydian. We can see from looking at the chart that Lydian has the following sequence of whole steps and half
			steps:</p>

			<p>Lydian: W W W H W W H</p>

			<img alt="mode" src={require(`../assets/explanationImages/lydian.png`)} className="modal-content__image" />

			<p>Lydian is known for its characteristic sharp 4th degree. So, in order to imply that this borrowed chord was in fact coming from the Lydian and mode (as opposed to being the Emaj<sup>7</sup> from Ionain) we would want to highlight that sharp 4th in our melody over top of our Emaj<sup>7</sup> chord.</p>

			<p>Over our next chord, Cmaj<sup>7</sup>, we would want to use the Aeolian scale in our melody:</p>

			<p>Aeolian: W H W W H W W</p>

			<img alt="modal chart" src={require(`../assets/explanationImages/aeolian.png`)} className="modal-content__image" />

			<p>
			Specifically, the minor 3rd and minor 7th scale degrees are what will hammer the Aeolian feel home. This will also apply to the final chord, the D7</p>

			<p>And finally, we borrowed our B<sup>7</sup> chord from the Ionian mode, so let's have a look at Ionain:</p>

			<p>Ionian: W W H W W W H</p>

			<img alt="modal chart" src={require(`../assets/explanationImages/ionian.png`)} className="modal-content__image" />

			<p>
			Here we would want to emphasize the scale degrees unique to Ionian, which would be the major 3rd and major 7th. So now we are tasked with writing a melody that weaves through the chords we have chosen while highlighting the mode of any given chords origin. This will likely introduce opportunities for chromatic movement within the melody, which will help the chords flow together more naturally.</p>

			<h3>Example 3</h3>

			<p>Okay, one final example. We don't always have to replace chords in our progression with other chords. We can also add chords to our progressions to create interest.
			Let's pick the key of D this time, and switch back to triads. In D Ionian, let's try the chord progression:</p>

			<p>G - F&#9839;m - Em - A - D.</p>

			<p>Now instead of swapping chords from our IV - iii - ii - V - I for chords in other modes,
			let's add some in!</p>

			<p>We could add the minor iv from Phrygian or Aeolian (depending on our melody) and the major II chord from Lydian into our progression so it looked like this: G - Gm - F&#9839;m - E - Em - A - D. Now we've taken a simple progression and created more movement with just a few simple additions. All it needs now is the melody!</p>

			<h2>Final Touches</h2>

			<p>Modal interchange is about borrowing chords with similar harmonic function from other parallel modes. With a tool like the Modal Interchange Chart, this is made much easier as we can pick a starting point, find a chord progression, swap chords in and out at a glance, and interpret the scales needed for creating our melodies. I encourage you to use it when writing your own music or analyzing the music of your favorite bands and artists. I hope this guide helped explain the Modal Interchange Charts uses and applications. Now, go forth and make music!</p>

			<p>Good luck!</p>
		</div>
	);
}
