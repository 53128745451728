import React, { Component } from 'react';

import Modal from 'react-modal';
import '../styles/Modal.css'
import ModalExplanation from './ModalExplanation';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'scroll',
		height: '80vh',
		width: '80vw',
	}
};

export default class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({
			modalIsOpen: true
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false
		});
	}
	render() {

		function getParent() {
			return document.querySelector('#root');
		}

		return(
			<div className="modalOuterContainer">
				<button onClick={this.openModal} className="openModalButton">User Guide</button>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					parentSelector={getParent}
					style={customStyles}
					portalClassName="ReactModalPortal"
					overlayClassName="ReactModal__Overlay"
					ariaHideApp={false}
				>
					<div className="closeModalContainer">
						<div onClick={this.closeModal} className="closeModalButton">
							&times;
						</div>
					</div>
					<ModalExplanation />
				</Modal>
			</div>
		)
	}
}